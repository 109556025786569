import { gql, useLazyQuery, useMutation } from "@apollo/client";
import styles from "./forms.module.scss";
import { Button, Form } from "react-bootstrap";
import Dropdown from "./Types/Dropdown";
import TextField from "./Types/TextField";
import { Formik } from "formik";
import CheckboxField from "./Types/CheckboxField";
import FileField from "./Types/FileField";
import RadioField from "./Types/RadioField";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DYNAMIC_FORM_FIELDS, RESPONSES } from "./queries";
import getRandomString from "../..//helpers/GetRandomString";
import axios from "axios";
import PaymentCheckPage from "../Payment/PaymentCheckPage";
import LabelField from "./Types/LabelField";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import DetailModal from "./DetailModal";
import uploadToFirebase from "../../helpers/FirebaseUpload";
import BillDeskComponent from "../Payment/billdeskComponent";
import RazorpayComponent from "../razorpay";
import MultipleFileField from "./Types/MultipleFile";

function PublicFormComponent() {
  const location = useLocation();
  const { id } = useParams();
  const uid = useSelector((state) => state?.auth?.uid);
  const userDetails = useSelector((state) => state.auth?.userDetails);
  const [initialValues, setInitialValues] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);
  const [paytmData, setPaytmData] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [searchParamsObj, setSearchparamsObj] = useState({});
  const [selectedAmountArr, setSelectedAmountArr] = useState([]);
  const [manualAmount, setManualAmount] = useState("");
  const [showBilldesk, setShowBilldesk] = useState({
    show: false,
    data: null,
  });
  const [showRazor, setShowRazor] = useState({
    show: false,
    data: null,
  });
  const [searchParams] = useSearchParams();
  const [attributes, setAttributes] = useState({
    gst: false,
    gstOptions: {
      gstNumber: "",
      gstName: "",
      gstFile: "",
    },
  });
  const [detailModalShow, setDetailModalShow] = useState({
    show: false,
    amount: null,
    orderId: null,
    custId: null,
    respId: null,
  });

  const formId = searchParams.get("formId");

  const formikRef = useRef(null);

  const shouldAutoSubmit = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchParamsObj = Object.fromEntries(urlParams.entries());

    return (
      formikRef.current?.values &&
      Object.keys(formikRef.current.values).length > 0 &&
      Object.keys(searchParamsObj).some((param) => param !== "formId") // Check for any params other than 'formId'
    );
  };

  useEffect(() => {
    if (shouldAutoSubmit()) {
      formikRef.current.submitForm();
    }
  }, [formikRef.current?.values]);

  const [createResponse] = useMutation(CREATE_RESPONSE);
  const [createTransaction] = useMutation(CREATE_TRANSACTION);
  const [getResponses, { data: responseData }] = useLazyQuery(RESPONSES);
  const [getDynamicData, { data: formFields }] = useLazyQuery(
    DYNAMIC_FORM_FIELDS,
    {
      variables: {
        id: id,
        formId: formId,
      },
      context: {
        clientName: "utility",
      },
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        let obj = {};
        let errorObj = [];
        let paramObj = {};

        searchParams.forEach((value, key) => {
          if (paramObj[key]) {
            if (Array.isArray(paramObj[key])) {
              paramObj[key].push(value);
            } else {
              paramObj[key] = [paramObj[key], value];
            }
          } else {
            paramObj[key] = value;
          }
        });

        setSearchparamsObj(paramObj);

        const payableAmount = paramObj["payableAmount"];
        const amountOptions = res?.dynamic?.amount?.options;

        if (payableAmount) {
          const payableAmounts = Array.isArray(payableAmount)
            ? payableAmount
            : [payableAmount];
          const matchingOptions = amountOptions?.filter((option) =>
            payableAmounts.includes(option.amount.toString())
          );

          if (res?.dynamic?.amount?.isManual) {
            setManualAmount(payableAmount);
          } else if (matchingOptions && matchingOptions.length > 0) {
            if (res?.dynamic?.amount?.isMulti) {
              setSelectedAmountArr(matchingOptions.map((option) => option._id));
            } else {
              setSelectedAmount(matchingOptions[0]._id);
            }
          }
        }

        if (!responseData?.responses?.length) {
          res?.dynamic?.questions?.map((question) => {
            if (paramObj[question?.title]) {
              obj[question?._id] = paramObj[question?.title];
            } else {
              obj[question?._id] = "";
            }
          });
          setInitialValues(obj);
        }

        res?.dynamic?.questions?.map((question) => {
          if (question?.required) {
            errorObj.push(question?._id);
          }
        });

        setRequiredFields(errorObj);

        if (
          res?.dynamic?.isGST?.status &&
          res?.dynamic?.amount?.options?.length
        ) {
          setAttributes((prev) => ({ ...prev, gst: true }));
        }
      },
    }
  );

  async function getResponseData(phone) {
    if (phone) {
      getResponses({
        variables: {
          formId,
          phone,
          checkSubmissionType: true,
        },
        context: {
          clientName: "utility",
        },
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        onCompleted: (res) => {
          // let obj = {};
          let obj = { gstName: "" };
          if (res?.responses?.length) {
            res?.responses[0]?.responses?.map((resp) => {
              obj[resp?._id] = resp?.answer;
            });
            setInitialValues(obj);
          } else {
            formFields?.dynamic?.questions?.map((question) => {
              obj[question?._id] = "";
            });
            setInitialValues(obj);
          }
          getDynamicData();
        },
      });
    }
  }

  async function transactionInit(amount, id, name, email, phone) {
    const confirmId = `FORM_${formFields?.dynamic?._id}_${id}_${getRandomString(
      4
    )}`;
    setDetailModalShow((prev) => ({ ...prev, show: false }));
    createTransaction({
      variables: {
        createTransactionInp: {
          mode: "online",
          amount: +amount,
          customerId: id,
          customer: name,
          email: email,
          phone: phone.toString(),
          paymentOrderId: confirmId,
          // paymentOrderId: "FORM_qwert2",
          description: "Form Payment",
          status: "Posted",
          module: "form",
        },
      },
      context: {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AUTHTOKEN}`,
          // Other headers...
        },
      },
    }).then((res) => {
      const ORDER_ID = res?.data?.createTransaction?.confirmId;
      handlePayment(
        amount,
        email,
        name,
        res?.data?.createTransaction?._id,
        ORDER_ID,
        phone
      );
    });
  }

  const makePayment = async (
    amount,
    orderId,
    custId,
    name,
    email,
    respId,
    phone
  ) => {
    await transactionInit(amount, respId, name, email, phone);
  };

  function handlePayment(amount, email, name, custId, orderId, phone) {
    const details = {
      gatewayId: formFields?.dynamic?.gid,
      amount: amount.toString(),
      email: email,
      firstName: name,
      custId: custId,
      orderId: orderId,
      phone: phone,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // setApiLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_PAYMENT_ENPOINT}/createTransactionToken`,
        details,
        config
      )
      .then((res) => {
        // setApiLoading(false);
        if (res.data?.data?.gatewaySdk === "billdesk") {
          const obj = res?.data?.data?.billdeskResponse?.links[1];
          setShowBilldesk({
            show: true,
            data: {
              merchantId: obj?.parameters?.mercid,
              bdOrderId: obj?.parameters?.bdorderid,
              authToken: obj?.headers?.authorization,
            },
          });
        } else if (res.data?.data?.gatewaySdk === "razorpay") {
          setShowRazor({
            show: true,
            data: {
              amount: res.data?.data?.razor?.amount,
              id: res.data?.data?.razor?.id,
              name,
              email,
              key: res.data?.data?.key,
              phone,
              formId: formFields?.dynamic?.formId,
            },
          });
        } else if (res?.data?.gatewaySdk === "eazypay") {
          if (res?.data?.url) {
            window.open(res?.data?.url, "_blank");
          }
        } else {
          if (!res?.data?.data?.paytm?.txnToken) {
            return toast.error(`Paytm System error! Try after sometime!`);
          }
          const data = res?.data?.data?.paytm;
          setPaytmData(data);
        }
      })
      .catch((err) => {
        // setApiLoading(false);
        toast.error(`Something went wrong : ${err.message}`);
      });
  }

  const types = {
    dropdown: "dropdown",
    text: "text",
    textArea: "textarea",
    checkbox: "checkbox",
    fileUpload: "file_upload",
    time: "time",
    radio: "multiple_choice",
    date: "date",
    label: "label",
    email: "email",
    number: "number",
  };

  function handleFieldAmount(arr, data) {

    const isAnyFieldWithQuantity = arr
      ?.map((item) => item?.additional)
      ?.flat()
      ?.some((el) => el?.isQuantity);



    if (isAnyFieldWithQuantity) {
      const normalizeValue = (str) =>
        str
          .trim()
          .toLowerCase()
          .replace(/\s*rs\.\s*/g, " rs. ") // Normalize "Rs." with consistent spacing
          .replace(/\/-?$/, ""); // Remove trailing "/-" or "/"

      const prices = arr
        .filter((el) =>
          el?.additional?.some((item) => {
            const userInput = data[el?._id];
            if (!userInput) return false;

            const selections = userInput.split(", ").map((input) => {
              const [, value] = input.split("-");
              return normalizeValue(value || input); // Normalize values
            });

            return selections.some(
              (normalizedValue) => normalizeValue(item?.value) === normalizedValue
            );
          })
        )
        .map((el) =>
          el?.additional
            ?.filter((item) => {
              const userInput = data[el?._id];
              if (!userInput) return false;

              const selections = userInput.split(", ").map((input) => {
                const [, value] = input.split("-");
                return normalizeValue(value || input); // Normalize values
              });

              return selections.some(
                (normalizedValue) =>
                  normalizeValue(item?.value) === normalizedValue
              );
            })
            ?.reduce((total, item) => {
              const userInput = data[el?._id];
              const selections = userInput.split(", ").map((input) => {
                const [quantityStr, value] = input.split("-");
                return {
                  quantity: parseInt(quantityStr, 10) || 1,
                  value: normalizeValue(value || input),
                };
              });

              const matchingSelection = selections.find(
                (selection) => normalizeValue(item?.value) === selection.value
              );

              const itemTotal = item?.isQuantity
                ? item?.price * matchingSelection?.quantity
                : item?.price;

              return total + (itemTotal || 0);
            }, 0)
        );

      return prices.reduce((total, price) => total + price, 0);
    } else {

      const prices = arr
        .filter((el) =>
          el?.additional?.some((item) => {
            if (item?.isQuantity) {
              return item?.value == data[el?._id]?.split("-")[1];
            } else {
              return item?.value == data[el?._id];
            }
          })
        )
        .map((el) =>
          el?.additional
            ?.filter((item) => {
              if (item?.isQuantity) {
                return item?.value == data[el?._id]?.split("-")[1];
              } else {
                return item?.value == data[el?._id];
              }
            })
            ?.reduce(
              (total, item) =>
                total + item?.isQuantity
                  ? item?.price * +data[el?._id]?.split("-")[0] || 1
                  : item?.price,
              0
            )
        );

      return prices.reduce((el, total) => total + el, 0);

    }



  }

  async function onSubmit(data, { resetForm }) {
    const answers = Object.keys(data).map((key) => ({
      _id: key,
      answer: Array.isArray(data[key]) ? data[key]?.join(",") : data[key],
    }));

    let formMap = [];
    formFields?.dynamic?.questions
      ?.filter((item) => item?.type == "label")
      .map((item) => {
        return formMap.push(item?._id);
      });

    const filteredAnswers = answers.filter(
      (item) => !formMap.includes(item?._id)
    );

    const isAnyFieldWithPrice = formFields?.dynamic?.questions?.some(
      (el) => el?.isPrice
    );

    const isPayment = formFields?.dynamic?.isPayment;
    const isManualAmount = formFields?.dynamic?.amount?.isManual;
    const isMultiAmount = formFields?.dynamic?.amount?.isMulti;
    const paymentMode = formFields?.dynamic?.questions?.filter((field) => {
      return field?.title === "Payment mode";
    });
    let paymentFrom = "";

    if (paymentMode) {
      const resp = answers.find((x) => x._id == paymentMode[0]?._id);
      paymentFrom = resp?.answer;
    }

    if (isPayment && !isManualAmount && !isAnyFieldWithPrice) {
      if (!isMultiAmount && !selectedAmount) {
        toast.error("Please select Amount!");
        return;
      } else if (isMultiAmount && selectedAmountArr?.length === 0) {
        toast.error("Please select Amount!");
        return;
      }
    }

    const amount = formFields?.dynamic?.amount?.isManual
      ? manualAmount
      : formFields?.dynamic?.amount?.isMulti
        ? formFields?.dynamic?.amount?.options
          ?.filter((option) => selectedAmountArr?.includes(option?._id))
          ?.reduce((total, item) => total + item?.amount, 0)
        : isAnyFieldWithPrice
          ? handleFieldAmount(
            formFields?.dynamic?.questions?.filter((el) => el?.isPrice),
            data
          )
          : formFields?.dynamic?.amount?.options?.filter(
            (option) => option?._id == selectedAmount
          )[0]?.amount;

    if (isPayment && !amount && paymentMode && paymentFrom != "International") {
      toast.error("Please select amount for Payment");
      return;
    }
    else {
      createResponse({
        variables: {
          createResponseInput: {
            formId: formFields?.dynamic?._id,
            userId: null,
            responses: filteredAnswers
              ?.map((item) => ({
                ...item,
                answer: String(item.answer),
              }))
              .filter((item) => item?.answer !== selectedAmount),
            gstInfo: formFields?.dynamic?.amount?.options?.length
              ? {
                gstName:
                  attributes.gstOptions.gstName === ""
                    ? null
                    : attributes.gstOptions.gstName,
                gstIn:
                  attributes.gstOptions.gstNumber === ""
                    ? null
                    : attributes.gstOptions.gstNumber,
                certificate: attributes.gstOptions.gstFile
                  ? await uploadToFirebase(
                    attributes.gstOptions.gstFile,
                    "dynamicGstCertificates",
                    `${getRandomString(4)}-${Date.parse()}-${attributes.gstOptions.gstFile?.name
                    }`
                  )
                  : null,
              }
              : null,
            amount: formFields?.dynamic?.amount?.isManual
              ? parseFloat(manualAmount)
              : null,
            amountId: formFields?.dynamic?.amount?.isMulti
              ? null
              : selectedAmount || null,
            amountIds: formFields?.dynamic?.amount?.isMulti
              ? selectedAmountArr
              : [],
          },
        },
        context: {
          clientName: "utility",
        },
      })
        .then((res) => {
          toast.success("Submitted Successfully!");
          if (
            formFields?.dynamic?.isPayment &&
            paymentFrom != "International"
          ) {
            let obj = {};
            const fieldsForPayment = formFields?.dynamic?.questions?.filter(
              (field) => {
                return (
                  field?.title === "Name" ||
                  field?.title === "Email" ||
                  field?.title === "Phone Number"
                );
              }
            );

            fieldsForPayment?.map((field) => {
              obj[field?.title] = data[field?._id];
            });

            const respId = res.data?.createResponseWithSubmissionType?._id;
            const name = obj["Name"];
            const email = obj["Email"];
            const phone = obj["Phone Number"];
            const newAmount = formFields?.dynamic?.isGST?.status
              ? amount + formFields?.dynamic?.isGST?.amount || 0
              : amount;
            transactionInit(newAmount, respId, name, email, phone);
          } else {
            resetForm();
            window.open("https://xlri.ac.in/thank-you-form", "_self");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }

  useEffect(() => {
    (async () => {
      await getDynamicData();
    })();
  }, []);

  useLayoutEffect(() => {
    if (responseData?.responses[0]?.responses?.length) {
      setSelectedAmount(
        formFields?.dynamic?.amount?.options
          ?.filter(
            (option) => option?._id == responseData?.responses[0]?.amountId
          )[0]
          ?.amount?.toString()
      );
      if (formFields?.dynamic?.amount?.isMulti) {
        setSelectedAmountArr(responseData?.responses[0]?.amountIds);
      }
    }
  }, [formFields, responseData]);

  return (
    <div
      style={
        formFields?.dynamic?.preference
          ? {
            backgroundColor: formFields?.dynamic?.preference?.bgColor,
            // fontFamily: formFields?.dynamic?.preference?.font,
            color: formFields?.dynamic?.preference?.fontColor,
            fontSize: formFields?.dynamic?.preference?.fontSize,
            height: "100vh",
          }
          : {}
      }
    >
      {paytmData && (
        <PaymentCheckPage
          data={paytmData}
          disableBtn={setBtnLoading}
          formData={formFields}
        // applicantId={applicantId}
        // refetch={refetch}
        />
      )}
      {showRazor.show && (
        <RazorpayComponent data={showRazor.data} setData={setShowRazor} />
      )}
      {showBilldesk.show && <BillDeskComponent data={showBilldesk.data} />}
      {formFields?.dynamic?.isHeadingVisible && (
        <div className={styles.mainStickyDivWrapper}>
          {formFields && (
            <div className={styles.mainStickyDivMain}>
              <div>
                <h6 className="text-center">{formFields?.dynamic?.title}</h6>
              </div>
            </div>
          )}
        </div>
      )}
      <div className={styles.mainStickyDivWrapper}>
        {formFields && initialValues && (
          <div className={styles.mainStickyDivMain}>
            <div className={styles.formWrapper}>
              <Formik
                innerRef={formikRef}
                initialValues={{
                  ...initialValues,
                  gstName: attributes.gstOptions.gstName,
                  gstNumber: attributes.gstOptions.gstNumber,
                  gstFile: attributes.gstOptions.gstFile,
                }}
                onSubmit={(data, { setSubmitting, setTouched, resetForm }) => {
                  const touchedFields = {};
                  Object.keys(data).forEach((key) => {
                    touchedFields[key] = true;
                  });
                  setTouched(touchedFields);
                  // Call your original onSubmit function
                  onSubmit(data, { resetForm });
                }}
                enableReinitialize
                validate={(data) => {
                  let errors = {};

                  formFields?.dynamic?.questions?.map((question, idx) => {
                    if (question?.required && question?.type === "checkbox") {
                      const selectedOptions = data[question?._id] || [];
                      if (selectedOptions.length === 0) {
                        errors[question?._id] = "Please select the checkbox";
                      }
                    } else if (
                      question?.required &&
                      question?.visible &&
                      data[question.visible.key] === question.visible.value &&
                      !data[question?._id] &&
                      idx !== 0
                    ) {
                      errors[question?._id] = "Required Field";
                    } else if (
                      question?.required &&
                      !question?.visible &&
                      !data[question?._id] &&
                      idx !== 0
                    ) {
                      errors[question?._id] = "Required Field";
                    }

                    if (question?.title === "Phone Number") {
                      const phoneNumber = data[question?._id];

                      if (!phoneNumber) {
                        errors[question?._id] = "Phone number is required";
                      } else if (!/^[0-9]{10}$/.test(phoneNumber)) {
                        errors[question?._id] =
                          "Invalid phone number. Must be 10 digits.";
                      }
                    }
                    if (
                      question?.visible &&
                      data[question.visible.key] === question.visible.value &&
                      !data[question?._id] &&
                      idx !== 0 &&
                      formFields?.dynamic?.questions?.filter(
                        (item) => item?._id === question.visible.key
                      )?.[0]?.required &&
                      question?.type !== "label"
                    ) {
                      errors[question?._id] = "Required Field";
                    }
                  });
                  return errors;
                }}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form className="d-flex flex-wrap" onSubmit={handleSubmit}>
                    {formFields?.dynamic?.questions?.map((field, idx) => (
                      <div
                        key={field?._id}
                        className={
                          field?.type == types.label
                            ? `${styles.formLabelSection}`
                            : field?.type == types.radio
                              ? `${styles.radioSection}`
                              : `${styles.formSection}`
                        }
                      >
                        {field?.type === types.dropdown ? (
                          <div className={styles.innerSection}>
                            <Dropdown
                              field={field}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              values={values}
                              errors={errors}
                              formFields={formFields?.dynamic?.questions}
                              idx={idx}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          </div>
                        ) : field?.type === types.text ? (
                          <div className={styles.innerSection}>
                            <TextField
                              field={field}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              values={values}
                              errors={errors}
                              idx={idx}
                              touched={touched}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          </div>
                        ) : field?.type === types.textArea ? (
                          <div className={styles.innerSection}>
                            <TextField
                              field={field}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              type={types?.textArea}
                              values={values}
                              idx={idx}
                              errors={errors}
                              touched={touched}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          </div>
                        ) : field?.type === types.checkbox ? (
                          <div className={styles.innerSection}>
                            <CheckboxField
                              field={field}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              values={values}
                              errors={errors}
                              idx={idx}
                              touched={touched}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          </div>
                        ) : field?.type === types.fileUpload &&
                          field?.isMultiple === true ? (
                          field?.visible ? (
                            formFields?.dynamic?.questions?.some(
                              (q) =>
                                q._id === field.visible.key &&
                                values[q._id] === field.visible.value
                            ) ? (
                              <MultipleFileField
                                field={field}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                values={values}
                                errors={errors}
                                idx={idx}
                                touched={touched}
                                requiredFields={requiredFields}
                                searchParamsObj={searchParamsObj}
                              />
                            ) : null
                          ) : (
                            <MultipleFileField
                              field={field}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              values={values}
                              errors={errors}
                              idx={idx}
                              touched={touched}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          )
                        ) : field?.type === types.fileUpload ? (
                          field?.isMultiple === true ? (
                            field?.visible ? (
                              formFields?.dynamic?.questions?.some(
                                (q) =>
                                  q._id === field.visible.key &&
                                  values[q._id] === field.visible.value
                              ) ? (
                                <MultipleFileField
                                  field={field}
                                  handleBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  errors={errors}
                                  idx={idx}
                                  touched={touched}
                                  requiredFields={requiredFields}
                                  searchParamsObj={searchParamsObj}
                                />
                              ) : null
                            ) : (
                              <MultipleFileField
                                field={field}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                values={values}
                                errors={errors}
                                idx={idx}
                                touched={touched}
                                requiredFields={requiredFields}
                                searchParamsObj={searchParamsObj}
                              />
                            )
                          ) : field?.visible ? (
                            formFields?.dynamic?.questions?.some(
                              (q) =>
                                q._id === field.visible.key &&
                                values[q._id] === field.visible.value
                            ) ? (
                              <FileField
                                field={field}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                values={values}
                                errors={errors}
                                idx={idx}
                                touched={touched}
                                requiredFields={requiredFields}
                                searchParamsObj={searchParamsObj}
                              />
                            ) : null
                          ) : (
                            <FileField
                              field={field}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              values={values}
                              errors={errors}
                              idx={idx}
                              touched={touched}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          )
                        ) : field?.type === types.time ? (
                          <div className={styles.innerSection}>
                            <TextField
                              field={field}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              type={types?.time}
                              errors={errors}
                              values={values}
                              idx={idx}
                              touched={touched}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          </div>
                        ) : field?.type === types.radio ? (
                          <div className={styles.innerSection}>
                            <RadioField
                              field={field}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              values={values}
                              errors={errors}
                              idx={idx}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          </div>
                        ) : field?.type === types.date ? (
                          <div className={styles.innerSection}>
                            <TextField
                              field={field}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              type={types?.date}
                              idx={idx}
                              values={values}
                              errors={errors}
                              touched={touched}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          </div>
                        ) : field?.type === types.email ? (
                          <div className={styles.innerSection}>
                            <TextField
                              field={field}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              type={types?.email}
                              values={values}
                              idx={idx}
                              errors={errors}
                              touched={touched}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          </div>
                        ) : field?.type === types.number ? (
                          <div className={styles.innerSection}>
                            <TextField
                              field={field}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              type={types?.number}
                              errors={errors}
                              values={values}
                              idx={idx}
                              setFieldValue={setFieldValue}
                              getResponses={getResponseData}
                              touched={touched}
                              requiredFields={requiredFields}
                              searchParamsObj={searchParamsObj}
                            />
                          </div>
                        ) : field?.type === types.label ? (
                          <LabelField field={field} values={values} />
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                    {formFields?.dynamic?.amount?.isManual ? (
                      <div className={styles.formSection}>
                        <div className={styles.innerSection}>
                          <Form.Group>
                            <Form.Label>
                              {formFields?.dynamic?.amount?.title}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="manualAmount"
                              value={manualAmount}
                              onChange={(e) => setManualAmount(e.target.value)}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    ) : formFields?.dynamic?.amount ? (
                      <div className={styles.formSection}>
                        <div className={styles.innerSection}>
                          <Form.Group>
                            <Form.Label>
                              {formFields?.dynamic?.amount?.title}
                            </Form.Label>
                            {formFields?.dynamic?.amount?.isMulti ? (
                              <>
                                {formFields?.dynamic?.amount?.options?.map(
                                  (option) => {
                                    const isChecked =
                                      selectedAmountArr.includes(option?._id);
                                    const isDisabled =
                                      searchParamsObj[
                                        "payableAmount"
                                      ]?.includes(option.amount.toString()) &&
                                      isChecked;
                                    return (
                                      <Form.Check
                                        type="checkbox"
                                        id={option._id} // Ensure each radio button has a unique ID
                                        key={option?._id}
                                        name={option?._id}
                                        value={option?._id}
                                        checked={isChecked}
                                        disabled={isDisabled}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            if (
                                              !selectedAmountArr.includes(
                                                e.target.value
                                              )
                                            ) {
                                              setSelectedAmountArr((prev) => [
                                                ...prev,
                                                e.target.value,
                                              ]);
                                            }
                                          } else {
                                            const newArr =
                                              selectedAmountArr.filter(
                                                (value) =>
                                                  value !== e.target.value
                                              );
                                            setSelectedAmountArr(newArr);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                        label={
                                          <label htmlFor={option._id}>
                                            {option.description}
                                          </label>
                                        }
                                      />
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <>
                                {formFields?.dynamic?.amount?.options?.map(
                                  (option) => {
                                    const isChecked =
                                      option?._id === selectedAmount;
                                    const isDisabled =
                                      searchParamsObj["payableAmount"] ===
                                      option.amount.toString() && isChecked;
                                    return (
                                      <Form.Check
                                        type="radio"
                                        id={option._id} // Ensure each radio button has a unique ID
                                        key={option._id}
                                        name={"amount"}
                                        value={option._id}
                                        checked={isChecked}
                                        disabled={isDisabled}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setSelectedAmount(e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        label={
                                          <label htmlFor={option._id}>
                                            {option.description}
                                          </label>
                                        }
                                      />
                                    );
                                  }
                                )}
                              </>
                            )}
                          </Form.Group>
                        </div>
                      </div>
                    ) : null}

                    {attributes.gst && (
                      <div className="d-flex flex-wrap w-100">
                        <div className={styles.formSection}>
                          <div className={styles.innerSection}>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={values.gstName}
                                // value={attributes.gstOptions.gstName}
                                onChange={(e) => {
                                  handleChange(e);
                                  setAttributes((prev) => ({
                                    ...prev,
                                    gstOptions: {
                                      ...attributes.gstOptions,
                                      gstName: e.target.value,
                                    },
                                  }));
                                }}
                                onBlur={handleBlur}
                                placeholder="GST Name"
                                name="gstName"
                              />
                              {errors.gstName && touched.gstName && (
                                <div className="errorMessage">
                                  {errors.gstName}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                        <div className={styles.formSection}>
                          <div className={styles.innerSection}>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                // value={attributes.gstOptions.gstNumber}
                                value={values.gstNumber}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  setAttributes((prev) => ({
                                    ...prev,
                                    gstOptions: {
                                      ...attributes.gstOptions,
                                      gstNumber: e.target.value,
                                    },
                                  }));
                                }}
                                placeholder="GST Number"
                                name="gstNumber"
                              />
                              {errors.gstNumber && touched.gstNumber && (
                                <div className="errorMessage">
                                  {errors.gstNumber}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                        <div className={styles.formSection}>
                          <div className={styles.innerSection}>
                            <Form.Group>
                              <Form.Label>GST File</Form.Label>
                              <Form.Control
                                type="file"
                                // value={values.gstFile}
                                onBlur={handleBlur}
                                onChange={(e) =>
                                  setAttributes((prev) => ({
                                    ...prev,
                                    gstOptions: {
                                      ...attributes.gstOptions,
                                      gstFile: e.target.files[0],
                                    },
                                  }))
                                }
                              />
                              {errors.gstFile && touched.gstFile && (
                                <div className="errorMessage">
                                  {errors.gstFile}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={styles.formSection}>
                      <div className={styles.innerButton}>
                        <Button
                          style={
                            formFields?.dynamic?.preference
                              ? {
                                background:
                                  formFields?.dynamic?.preference?.ctaColor,
                                color:
                                  formFields?.dynamic?.preference
                                    ?.ctaFontColor,
                              }
                              : {}
                          }
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
      {detailModalShow.show && (
        <DetailModal
          show={detailModalShow.show}
          onHide={() =>
            setDetailModalShow({
              show: false,
              amount: null,
              orderId: null,
              custId: null,
            })
          }
          details={detailModalShow}
          makePayment={makePayment}
        />
      )}
    </div>
  );
}

export default PublicFormComponent;

const CREATE_RESPONSE = gql`
  mutation createResponseWithSubmissionType(
    $createResponseInput: CreateResponseInput!
  ) {
    createResponseWithSubmissionType(
      createResponseInput: $createResponseInput
    ) {
      _id
      formId
      status
    }
  }
`;

const CREATE_TRANSACTION = gql`
  mutation createTransaction($createTransactionInp: CreateTransactionInput!) {
    createTransaction(createTransactionInp: $createTransactionInp) {
      _id
      invoice
      mode
      tranactionType
      depositId
      amount
      enterDate
      settledDate
      customerId
      customer
      confirmId
      description
      document
      refundDate
      status
      __typename
    }
  }
`;
